import { PageControl } from "@model/application";

export class Utils {
	static isMobile() {
		return window && window.matchMedia('(max-width: 767px)').matches;
	}

	static mountPageControl(pageControl: PageControl): string {
		let result = '';

		if (!pageControl) {
			return result;
		}

		Object.entries(pageControl).forEach(([key, value]) => {
			result += `${key}=${value}&`;
		});

		return result;
	}

	static filterAutocomplete(items: any[], value: string, field: string = 'name', field2: string = '') {
        if (typeof value === 'string' && items.length) {
            const filterValue = value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            return items.filter(option => {
                return (
                    filterValue === '' ||
                    String(field2 ? option[field][field2] : option[field])
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(filterValue)
                );
            });
        }
    }

    static capitalizeCase(str: string): string {
        let result: string = '';

        str.split(' ').forEach((word, index) => {
            if (index) {
                result += ` ${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
                return
            }
            result += `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
        })

        return result;
    }

    static strToDateMonthYear(str: string): string {
        const [year, month] = str.split('-');
        return `${month}${year}`;
    }

    static dateToStrMonthYear(str: string): string {
        return str.slice(2) + "-" + str.slice(0, 2);
    }

    static parserPermissions(permission: string){
        const permissionMap: { [key: string]: string } = {
            'view-users': 'Visualizar Usuários',
            'read-users': 'Ler Usuários',
            'create-users': 'Criar Usuários',
            'edit-users': 'Editar Usuários',
            'update-password-users': 'Atualizar Senha Usuários',
            'delete-users': 'Deletar Usuários',
            'view-roles': 'Visualizar Funções',
            'read-roles': 'Ler Funções',
            'create-roles': 'Criar Funções',
            'edit-roles': 'Editar Funções',
            'delete-roles': 'Deletar Funções',
            'view-permissions': 'Visualizar Permissões',
            'read-permissions': 'Ler Permissões',
            'create-permissions': 'Criar Permissões',
            'edit-permissions': 'Editar Permissões',
            'delete-permissions': 'Deletar Permissões',
            'view-domains': 'Visualizar Domínios',
            'read-domains': 'Ler Domínios',
            'create-domains': 'Criar Domínios',
            'edit-domains': 'Editar Domínios',
            'delete-domains': 'Deletar Domínios',
            'view-servers': 'Visualizar Servidores',
            'read-servers': 'Ler Servidores',
            'create-servers': 'Criar Servidores',
            'edit-servers': 'Editar Servidores',
            'delete-servers': 'Deletar Servidores',
            'view-db-servers': 'Visualizar Servidores de Banco de Dados',
            'read-db-servers': 'Ler Servidores de Banco de Dados',
            'create-db-servers': 'Criar Servidores de Banco de Dados',
            'edit-db-servers': 'Editar Servidores de Banco de Dados',
            'delete-db-servers': 'Deletar Servidores de Banco de Dados',
            'view-banks': 'Visualizar Bancas',
            'read-banks': 'Ler Bancas',
            'create-banks': 'Criar Bancas',
            'edit-banks': 'Editar Bancas',
            'delete-banks': 'Deletar Bancas',
            'view-banks-performance': 'Visualizar Desempenho das Bancas',
            'read-banks-performance': 'Ler Desempenho das Bancas',
            'create-banks-performance': 'Criar Desempenho das Bancas',
            'edit-banks-performance': 'Editar Desempenho das Bancas',
            'delete-banks-performance': 'Deletar Desempenho das Bancas',
            'view-banks-credit-cassino': 'Visualizar Crédito de Cassino das Bancas',
            'read-banks-credit-cassino': 'Ler Crédito de Cassino das Bancas',
            'create-banks-credit-cassino': 'Criar Crédito de Cassino das Bancas',
            'edit-banks-credit-cassino': 'Editar Crédito de Cassino das Bancas',
            'delete-banks-credit-cassino': 'Deletar Crédito de Cassino das Bancas',
            'view-logs': 'Visualizar Logs',
            'read-logs': 'Ler Logs',
            'create-logs': 'Criar Logs',
            'edit-logs': 'Editar Logs',
            'delete-logs': 'Deletar Logs',
            'view-jobs': 'Visualizar Trabalhos',
            'read-jobs': 'Ler Trabalhos',
            'create-jobs': 'Criar Trabalhos',
            'edit-jobs': 'Editar Trabalhos',
            'delete-jobs': 'Deletar Trabalhos',
            'view-contracts': 'Visualizar Contratos',
            'read-contracts': 'Ler Contratos',
            'create-contracts': 'Criar Contratos',
            'edit-contracts': 'Editar Contratos',
            'edit-contracts-credit-cassino': 'Editar crédito de cassino do contrato',
            'delete-contracts': 'Deletar Contratos',
            'view-contracts-attachment': 'Visualizar Anexos de Contratos',
            'read-contracts-attachment': 'Ler Anexos de Contratos',
            'create-contracts-attachment': 'Criar Anexos de Contratos',
            'edit-contracts-attachment': 'Editar Anexos de Contratos',
            'delete-contracts-attachment': 'Deletar Anexos de Contratos',
            'view-contracts-credit': 'Visualizar Crédito de Contratos',
            'read-contracts-credit': 'Ler Crédito de Contratos',
            'create-contracts-credit': 'Criar Crédito de Contratos',
            'edit-contracts-credit': 'Editar Crédito de Contratos',
            'delete-contracts-credit': 'Deletar Crédito de Contratos',
            'view-payments': 'Visualizar Cobranças',
            'read-payments': 'Ler Cobranças',
            'create-payments': 'Criar Cobranças',
            'edit-payments': 'Editar Cobranças',
            'delete-payments': 'Deletar Cobranças',
            'create-payment-pix': 'Criar Cobrança PIX',
            'undo-payment': 'Desfazer Cobrança',
            'view-payment-categories': 'Visualizar Categorias de Cobrança',
            'read-payment-categories': 'Ler Categorias de Cobrança',
            'create-payment-categories': 'Criar Categorias de Cobrança',
            'edit-payment-categories': 'Editar Categorias de Cobrança',
            'delete-payment-categories': 'Deletar Categorias de Cobrança',
            'view-financial-postings': 'Visualizar Lançamentos Financeiros',
            'read-financial-postings': 'Ler Lançamentos Financeiros',
            'create-financial-postings': 'Criar Lançamentos Financeiros',
            'edit-financial-postings': 'Editar Lançamentos Financeiros',
            'delete-financial-postings': 'Deletar Lançamentos Financeiros',
            'cancel-financial-postings': 'Cancelar Lançamentos Financeiros',
            'view-notifications': 'Visualizar Notificações',
            'read-notifications': 'Ler Notificações',
            'create-notifications': 'Criar Notificações',
            'edit-notifications': 'Editar Notificações',
            'delete-notifications': 'Deletar Notificações',
            'view-gateways': 'Visualizar Gateways',
            'read-gateways': 'Ler Gateways',
            'create-gateways': 'Criar Gateways',
            'edit-gateways': 'Editar Gateways',
            'delete-gateways': 'Deletar Gateways',
            'view-deposits': 'Visualizar Depósitos',
            'read-deposits': 'Ler Depósitos',
            'create-deposits': 'Criar Depósitos',
            'edit-deposits': 'Editar Depósitos',
            'delete-deposits': 'Deletar Depósitos',
            'cancel-deposits': 'Cancelar Depósitos',
            'view-withdraw': 'Visualizar Saques',
            'read-withdraw': 'Ler Saques',
            'create-withdraw': 'Criar Saques',
            'edit-withdraw': 'Editar Saques',
            'delete-withdraw': 'Deletar Saques',
            'view-withdraw-request': 'Visualizar Solicitação de Saque',
            'read-withdraw-request': 'Ler Solicitação de Saque',
            'create-withdraw-request': 'Criar Solicitação de Saque',
            'edit-withdraw-request': 'Editar Solicitação de Saque',
            'approve-withdraw-request': 'Aprovar Solicitação de Saque',
            'refuse-withdraw-request': 'Recusar Solicitação de Saque',
            'delete-withdraw-request': 'Deletar Solicitação de Saque',
            'view-settings': 'Visualizar Configurações',
            'read-settings': 'Ler Configurações',
            'create-settings': 'Criar Configurações',
            'edit-settings': 'Editar Configurações',
            'delete-settings': 'Deletar Configurações',
            'view-ggr': 'Visualizar GGR',
            'read-ggr': 'Ler GGR',
            'create-ggr': 'Criar GGR',
            'edit-ggr': 'Editar GGR',
            'delete-ggr': 'Deletar GGR',
            'exec-domain-setup': 'Executar Configuração de Domínio',
            'exec-domain-verify': 'Executar Verificação de Domínio',
            'exec-app-setup': 'Executar Configuração de Aplicativo',
            'exec-app-enable': 'Habilitar Aplicativo',
            'exec-app-disable': 'Desabilitar Aplicativo',
            'exec-app-backup': 'Executar Backup de Aplicativo',
            'exec-app-delete': 'Deletar Aplicativo',
            'exec-app-block': 'Bloquear Aplicativo',
            'exec-app-unlock': 'Desbloquear Aplicativo',
            'exec-app-sync-contract': 'Sincronizar Contrato de Aplicativo',
            'exec-app-update-proxy-bank': 'Atualizar Proxy de Banco de Aplicativo',
            'view-home': 'Visualizar Página Inicial',
            'view-reports': 'Visualizar Relatórios',
            'view-maintenance': 'Visualizar Manutenção',
            'view-expiration-domains': 'Visualizar Expiração de Domínios',
            'view-dashboard': 'Visualizar Painel de Controle',
            'view-payment-dashboard': 'Visualizar Painel de Pagamentos',
            "view-financial-reports": "Visualizar relatórios financeiros",
            "view-financial": "Visualizar Painel Financeiro",
            'view-product': 'Visualizar Produtos',
            'read-product': 'Ler Produtos',
            'create-product': 'Criar Produtos',
            'edit-product': 'Editar Produtos',
            'delete-product': 'Deletar Produtos',
            'view-seller': 'Visualizar Vendedores',
            'read-seller': 'Ler Vendedores',
            'create-seller': 'Criar Vendedores',
            'edit-seller': 'Editar Vendedores',
            'delete-seller': 'Deletar Vendedores',
            'view-message': 'Visualizar Avisos',
            'read-message': 'Ler Avisos',
            'create-message': 'Criar Avisos',
            'edit-message': 'Editar Avisos',
            'delete-message': 'Deletar Avisos',
            'view-system': 'Visualizar Sistema',
            'read-system': 'Ler Sistema',
            'view-order': 'Visualizar Pedido',
            'read-order': 'Ler Pedido',
            'create-order': 'Criar Pedido',
            'edit-order': 'Editar Pedido',
            'approve-order': 'Aprovar Pedido',
            'refuse-order': 'Reprovar Pedido',
            'delete-order': 'Deletar Pedido',
            'view-order-attachment': 'Visualizar Anexo de Pedido',
            'read-order-attachment': 'Ler Anexo de Pedido',
            'create-order-attachment': 'Criar Anexo de Pedido',
            'edit-order-attachment': 'Editar Anexo de Pedido',
            'delete-order-attachment': 'Deletar Anexo de Pedido',
            'view-promotional-code': 'Visualizar Código Promocional',
            'read-promotional-code': 'Ler Código Promocional',
            'create-promotional-code': 'Criar Código Promocional',
            'generate-promotional-code': 'Gerar Código Promocional',
            'edit-promotional-code': 'Editar Código Promocional',
            'delete-promotional-code': 'Deletar Código Promocional',
          };
          
        return permissionMap[permission] || 'Permissão Desconhecida';
    }
}